
.creds-wrapper {
  display: flex;
  justify-content: center;
}

.creds-spinner-wrapper {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.creds-spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.creds-spinner-title {
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 14px;
}

.creds-container {
  width: 60%;
}

.creds-notification {
  position: relative;
  background: rgba(73, 167, 200, 0.2);
  border: 1px solid #49A7C8;
  border-radius: 4px;
  padding: 20px;
  font-size: 24px;
}

.creds-notification-link {
  color: #0740D3;
}

.creds-notification-text {
  font-size: 20px;
}

.creds-notification-close {
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
}

.creds-body {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  min-height: 500px;
}

.creds-radiobuttons {
  width: 30%;
  max-width: 343px;
  background-color: #FFFFFF;
  border: 1px solid #EFF0F1;
  padding: 22px 0px 22px 32px;
}

.creds-radiobuttons-item {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.creds-radiobuttons-icon {
  width: 15px;
  height: 15px;
  min-width: 15px;
  border-radius: 8px;
  background-color: #51A221;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.creds-radiobuttons-icon.selected {
  background-color: #49A7C8;
}

.creds-radiobuttons-icon.no-any-credentials {
  background-color: #D5D8DF;
}

.creds-radiobuttons-icon.not-verified {
  background-color: #F1A852;
}

.creds-body-details {
  width: 70%;
  padding-left: 74px;
}

.creds-body-details-terminal {
  display: flex;
}

.creds-body-details-terminal-icon {
  border: 1px solid #EFF0F1;
  width: 84px;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
}

.creds-body-details-terminal-icon > img {
  width: 69px;
  height: 69px;
  object-fit: contain;
}

.creds-body-details-terminal-name {
  margin: auto 0;
  padding-left: 17px;
  font-size: 20px;
}

.creds-body-details-prompt {
  margin: 23px 0px 34px 0px;
}

.creds-form-block {
  position: relative;
}

.creds-form-warning {
  position: absolute;
  bottom: -25px;
  color: #D3000B;
}

.creds-form-input-container {
  display: flex;
  margin-bottom: 10px;
}

.creds-form-input-title {
  font-size: 16px;
  margin-bottom: 5px;
}

.creds-form-input {
  margin-right: 29px;
  position: relative;
}

.creds-form-input > form > input {
  border: 1px solid #E3E5E8;
  border-radius: 4px;
  padding: 11px 0px 11px 10px;
}

.creds-form-tip {
  border-left: 3px solid #49A7C8;
  padding-left: 8px;
}

.creds-form-tip.valid {
  border-left: 3px solid #51A221;
}

.creds-form-button-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 50px;
  position: relative;
}

.creds-form-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.creds-form-button {
  background-color: #F1A852;
  color: #FFFFFF;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 17px;
  transition: opacity .1s;
}

.creds-form-button:hover {
  opacity: .8;
  cursor: pointer;
  transition: opacity .1s;
}

.creds-form-skip {
  margin-top: 10px;
  color: #2361FF;
  cursor: pointer;
}

.creds-form-validated-icon {
  position: absolute;
  left: -25px;
  top: 12px;
}

.creds-form-privacy-policy {
  position: absolute;
  top: 30px;
  font-style: italic;
  font-size: 11px;
  color: #8E98A4;
}

.creds-form-privacy-link {
  color: #2361FF;
}
