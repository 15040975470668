
.book-appointment-modal-confirmed-wrapper {
  padding: 28px 50px;
  color: #18294D;
  font-size: 16px;
  line-height: 19px;
}

.book-appointment-modal-confirmed-title {
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 30px;
}

.book-appointment-modal-confirmed-container-number {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.book-appointment-modal-confirmed-container-number > div:first-child {
  margin-right: 8px;
}

.book-appointment-modal-confirmed-appoitnment {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.book-appointment-modal-confirmed-appoitnment-row {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.book-appointment-modal-confirmed-appoitnment-value {
  margin-right: 8px;
}

.book-appointment-modal-confirmed-confirmation-number {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.book-appointment-modal-confirmed-confirmation-number-value-row {
  display: flex;
  align-items: center;
  margin-top: 5px;
  position: relative;
}

.book-appointment-modal-confirmed-confirmation-number-value {
  font-size: 22px;
  line-height: 26px;
  margin-right: 8px;
}

.book-appointment-modal-confirmed-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.book-appointment-modal-button.go-to-appoitnments {
  padding: 9px 15px;
}

.book-appointment-modal-button.close {
  background-color: #8E98A4;
}

.book-appointment-modal-confirmed-confirmation-copied {
  position: absolute;
  top: -15px;
  right: -41px;
  font-size: 14px;
  background-color: #D5D8DF;
  border-radius: 2px;
  padding: 0 3px;
}

.book-appointment-modal-confirmed-confirmation-copied.appoitnment {
  top: -18px;
}
