.react-modal-wrapper {
  padding: 0 0;
} 

.stop-the-clock-modal {
  padding: 0px 20px;
  color: #223349;
}

.stop-the-clock-modal__close {
  position: absolute;
  top: 13px;
  right: 13px;
}

.stop-the-clock-modal__close:hover {
  cursor: pointer;
}

.stop-the-clock-modal__title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
  text-align: center;
  color: #EFA951;
  margin-bottom: 20px;
}

.stop-the-clock-modal__subtitle {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.stop-the-clock-modal__textarea-container {
  margin: 20px 0 15px 0;
  position: relative;
}

.stop-the-clock-modal__error {
  font-size: 16px;
  color: #E66D69;
}

.stop-the-clock-modal__textarea-label-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.stop-the-clock-modal__textarea-label {
  top: -9px;
  position: absolute;
  background-color: #FFFFFF;
  padding: 0 5px;
  font-weight: 500;
  font-size: 12px;
}

.stop-the-clock-modal__textarea {
  width: 100%;
  padding: 19px 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid rgba(38, 50, 56, .16);
  box-sizing: border-box;
  border-radius: 8px;
  resize: none;
}

.stop-the-clock-modal__textarea:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(38, 50, 56);
}

.stop-the-clock-modal__textarea::placeholder {
  text-align: center;
  color: #8D97A3;
}

.stop-the-clock-modal__recipients {
  min-height: 160px;
  position: relative;
}

.stop-the-clock-modal__recipients-link {
  position: absolute;
  font-size: 16px;
  text-decoration-line: underline;
  cursor: pointer;
}

.stop-the-clock-modal__recipients-label-italic {
  font-style: italic;
  font-weight: 300;
}
