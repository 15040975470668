
.my-appt-details {
  padding: 20px 76px 20px 76px;
  color: #18294D;
}

.my-appt-details-title {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 25px;
}

.my-appt-details-container {
  display: flex;
}

.my-appt-details-col {
  width: 50%;
}

.my-appt-details-block {
  margin-bottom: 20px;
}

.my-appt-details-block-title {
  font-size: 16px;
}

.my-appt-details-block-title {
  font-weight: bold;
}

.my-appt-details-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.my-appt-details-button {
  padding: 9px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F1A852;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  border-radius: 17px;
  transition: opacity .1s;
}

.my-appt-details-button:hover {
  opacity: .8;
  cursor: pointer;
  transition: opacity .1s;
}
