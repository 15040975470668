.font {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
  
.font h1 {
    font-size: 1.625rem;
}

.font h2 {
    font-size: 1.25rem;
}

.font h3 {
    font-size: 1.125rem;
}

.font p {
    font-size: 1rem;
}

.font h4 {  
    font-size: 0.875rem;
}

.font h5 {
    font-size: 0.75rem;
}

.font h6 {
    font-size: 0.625rem;
}

.font input {
    font-size: 0.875rem;
    font-weight: 500;

}

/* clears the ‘X’ from Internet Explorer */
.font input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
.font input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
.font input[type="search"]::-webkit-search-decoration,
.font input[type="search"]::-webkit-search-cancel-button,
.font input[type="search"]::-webkit-search-results-button,
.font input[type="search"]::-webkit-search-results-decoration { display: none; }

.font input:-webkit-autofill,
.font input:-webkit-autofill:hover, 
.font input:-webkit-autofill:focus, 
.font input:-webkit-autofill:active  {
  transition: background-color 5000s;
  /* -webkit-text-fill-color: #fff !important; */
}


.font textarea {
    font-size: 0.75rem;
}

.font ::placeholder {
    font-size: 0.875rem;
    font-weight: 400;
    opacity: 1; /* Firefox */
  }