.scheduleWrapper {
    padding: 20px;
}

.table {
    width: 100%;
    border-collapse: separate;
    border: solid #313a4c 1px;
    border-radius: 5px;
    border-spacing: 0;
}

.tableWrapper {
    padding-top: 50px;
}

.cancelButtonWrapper {
    padding-bottom: 20px;
}

.weekPageUl {
    margin: 0;
    padding: 0;
}

.weekPageUl > li {
    display: inline;
    list-style-type: none;
    padding-right: 20px;
    float: left;
}

.weekPageItem {
    cursor: pointer;
    color: rgb(22, 72, 219);
    border-bottom: 1px dashed rgba(22, 72, 219, .7);
    transition: color 100ms linear;
}

.weekPageItem:hover {
    color: rgb(219, 22, 22);
    border-bottom: 1px dashed rgba(219, 22, 22, .7);
}

.tableHeaders {
    text-align: left;
}


.table td, th {
    border-left: solid #313a4c 1px;
    border-top: solid #313a4c 1px;
    padding: 10px 20px;
}

.table th {
    background-color: #1F334B;
    border-top: none;
}

.table tr {
    transition: background-color 100ms linear;
}

.table tr:nth-child(2n) {
    background-color: #efefef;
    border-top: none;
}

.table tr:nth-child(n):hover {
    background-color: #dfe3ea;
    border-top: none;
}


.table td:first-child {
    border-left: none;
}

.table th:first-child {
    border-top-left-radius: 5px;
}

.table th:last-child {
    border-top-right-radius: 5px;
}

.tableHeaderDate {
    font-size: 11px;
    color: #fff;
}

.dateIteration0 {
    background-color: #313a4c !important;
}

.dateIteration1 {
    background-color: #2c354b !important;
}

.dateIteration2 {
    background-color: #212738 !important;
}

.tableHeaderShift1 {
    color: #43caff;
    font-size: 11px;
}

.tableHeaderShift2 {
    color: #ffb043;
    font-size: 11px;
}


.tableItemEditRow {
    cursor: pointer;
    position: relative;
}

.selectItemEdit {
    position: absolute;
    top: 5px;
    left: 10px;
    padding: 5px;
}

.selectItemEdit option {
    padding: 10px;
}

.optionItemEdit {
    background-color: #d9d9d9;
}
