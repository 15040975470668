
.ea-chassis {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  margin-top: 5px;
}

.ea-chassis-name {
  margin-left: 5px;
}
