.PhoneInput {
    font-size: 12px;
    border: 1px solid #dde3ff;
    border-radius: 0.25rem;
}

.PhoneInput:hover {
    border: 1px solid #0025F1;
}


.PhoneInput .PhoneInput--focus {
	color: none;

}

.PhoneInputInput::placeholder {
	color: #000106;
}

.PhoneInputInput {
	outline: none;
    border-radius: 0;
    -webkit-appearance: none;
    appearance: none;
    transition: border .1s;
    font-size: inherit;
    color: #1f334b;
    font-size: 12px;
    padding: 17px 18.5px 17px 34px;
    border-radius: 0.25rem;

    /* background-color: rgb(226 228 231); */
}

.PhoneInputCountryIcon.PhoneInputCountryIcon--border {
	box-shadow: none;
    margin-left: 1rem;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: none;

}

.PhoneInputCountry {
	padding: 0 5px;
}

.PhoneInputCountryIcon {
	width: 1.35em !important;
    height: 1.01em !important;
    box-sizing: content-box;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	border: none !important;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	color: #1f334b !important;
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	color: inherit !important;
}

.PhoneInputCountrySelectArrow {
	display: block;
    content: '';
    width: 0.3em;
    height: 0.3em;
    margin-left: 0.35em;
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-right-width: 1px;
    -webkit-transform: rotate(
45deg);
    transform: rotate(
45deg);
    opacity: 0.45;
}
