
.book-appointment-modal-error-wrapper {
  padding: 40px 50px 34px 50px;
  color: #18294D;
  font-size: 16px;
  line-height: 19px;
}

.book-appointment-modal-error-title {
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 8px;
}


.book-appointment-modal-error-subtitle {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 50px;
}

.book-appointment-modal-error-button-wrapper {
  display: flex;
  justify-content: center;
}

.book-appointment-modal-error-button-wrapper.pick-another-slot-button-container {
  margin-bottom: 26px;
}

.book-appointment-modal-button.pick-another-slot {
  padding: 9px 15px;
}

.book-appointment-modal-button.close {
  background-color: #8E98A4;
}
