
.book-appointment-modal-loading-wrapper {
  padding: 28px 50px;
  color: #18294D;
}

.book-appointment-modal-loading-title {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  margin-bottom: 10px;
}

.book-appointment-modal-loading-subtitle {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.book-appointment-modal-loading-logo-wrapper {
  display: flex;
  justify-content: center;
}

.book-appointment-modal-loading-logo {
  width: 136px;
  height: 140px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.book-appointment-modal-loading-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
