
.gate-schedule {
  padding: 0 14px;
  max-width: 1300px;
  margin: 0 auto 100px auto;
}

.gate-schedule-header {
  padding: 0 120px;
  margin: 35px 0;
}

.gate-schedule-header-title {
  font-weight: bold;
  font-size: 32px;
  color: #19294D;
  border-bottom: 3px solid #19294D;
}

.gate-schedule-table-wrapper.current {
  margin-bottom: 50px;
}

.gate-schedule-table-wrapper-header {
  padding: 0 120px;
  color: #19294D;
}

.gate-schedule-table-wrapper-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(25, 41, 77, 0.08);
  padding-bottom: 15px;
}

.gate-schedule-table-wrapper-header-week-title {
  font-weight: bold;
  font-size: 24px;
  margin-right: 10px;
}

.gate-schedule-table-wrapper-header-week-dates {
  font-size: 20px;
}

.gate-schedule-table-wrapper-header-updated {
  font-size: 14px;
  line-height: 16px;
  color: rgba(25, 41, 77, 0.6);
}

.gate-schedule-table-header {
  display: flex;
  align-items: center;
  padding: 22px 0 12px 0;
}

.gate-schedule-table-header-cell {
  position: relative;
  width: 6.5%;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #828998;
}

.gate-schedule-table-today-label {
  font-size: 9px;
  position: absolute;
  top: -18px;
  left: calc(50% - 22px);
  color: #FFFFFF;
  background-color: #51A221;
  padding: 0 7px;
}

.gate-schedule-table-header-cell.terminal-name {
  width: 9%;
  text-align: left;
  padding-left: 20px;
}

.gate-schedule-table-body {
  background: #FFFFFF;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.gate-schedule-table-body-row {
  display: flex;
  align-items: center;
}

.gate-schedule-table-body-row-cell {
  width: 6.5%;
  display: flex;
  justify-content: center;
  padding: 16px 0;
  min-height: 62px;
}

.gate-schedule-table-body-row-cell.today {
  background-color: #FAFAFA;
}

.gate-schedule-table-body-row-cell.terminal-name {
  width: 9%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #19294D;
  padding-left: 20px;
}

.gate-schedule-table-body-row-cell-content {
  padding: 5px 9px;
  border-radius: 20px;
  text-transform: capitalize;
}

.gate-schedule-table-body-row-cell-content.open {
  color: #4A9C66;
  background-color: #E3FEEC;
}

.gate-schedule-table-body-row-cell-content.closed {
  color: #855555;
  background-color: #F9D0D0;
}

.gate-schedule-table-body-row-cell-content.tba {
  color: #9e9e9e;
  background-color: #dbdbdb;
}

.gate-schedule-table-body-row-cell-content.limited {
  color: #A28767;
  background-color: #FCECD9;
}


.gate-schedule-download-button {
  padding: 8.5px 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #18294D;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 16px;
  border-radius: 19px;
  transition: opacity .2s;
}

.gate-schedule-download-button:hover {
  opacity: .8;
  transition: opacity .2s;
  cursor: pointer;
}
