

.book-appointment-modal-wrapper {
  padding: 0 50px;
  font-size: 16px;
}

.book-appointment-modal-title {
  font-size: 22px;
  line-height: 26px;
  color: #18294D;
  margin: 20px 0px;
}

.book-appointment-modal-block {
  margin-bottom: 20px;
  position: relative;
}

.book-appointment-modal-block.container-number {
  margin-bottom: 0;
}


.book-appointment-modal-checkbox-container {
  display: block;
  position: relative;
  padding-left: 23px;
  cursor: pointer;
  font-size: 16px;
  color: #1F334B;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.book-appointment-modal-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.book-appointment-modal-checkbox-checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  width: 14px;
  height: 14px;
  background-color: #FFFFFF;
  border: 1px solid #D5D8DF;
  border-radius: 2px;
}

.book-appointment-modal-checkbox-container:hover input ~ .book-appointment-modal-checkbox-checkmark {
  background-color: #D5D8DF;
}

.book-appointment-modal-checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.book-appointment-modal-checkbox-container input:checked ~ .book-appointment-modal-checkbox-checkmark:after {
  display: block;
}

.book-appointment-modal-checkbox-container .book-appointment-modal-checkbox-checkmark:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid #35475D;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.book-appointment-modal-checkbox-label {
  display: flex;
  align-items: center;
}

.book-appointment-modal-label:hover {
  cursor: pointer;
}

.book-appointment-modal-field-title {
  margin-bottom: 5px;
}

.book-appointment-modal-field-title.dropdown-title {
  position: relative;
  bottom: -19px;
}

.book-appointment-modal-input {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #D5D8DF;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  padding: 9px 11px 8px 11px;
}

.book-appointment-modal-input::placeholder {
  color: #8E98A4;
}

.book-appointment-modal-input:disabled {
  color: #8E98A4;
  background-color: #D5D8E1;
}

.book-appointment-modal-checkbox-chassis-number {
  position: relative;
  padding-left: 23px;
  margin-top: 8px;
}

.book-appointment-modal-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0 15px 0;
}

.book-appointment-modal-button {
  padding: 9px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F1A852;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  border-radius: 17px;
  transition: opacity .1s;
}

.book-appointment-modal-button.disabled {
  background-color: #8E98A4;
}

.book-appointment-modal-button:hover {
  opacity: .8;
  cursor: pointer;
  transition: opacity .1s;
}

.book-appointment-modal-button.disabled:hover {
  cursor: auto;
  opacity: 1;
}

.book-appointment-modal-cancel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-appointment-modal-cancel {
  font-size: 14px;
  line-height: 16px;
  color: #2361FF;
  transition: opacity .1s;
  margin-bottom: 20px;
}

.book-appointment-modal-cancel:hover {
  cursor: pointer;
  opacity: .8;
  transition: opacity .1s;
}

.book-appointment-modal-warning {
  position: absolute;
  bottom: -17px;
  left: 0;
  color: #D3000B;
  font-size: 12px;
}

.book-appointment-modal-warning.chassis-number {
  left: 23px;
}

.MuiAutocomplete-BookAppointmentModal-icon-container {
  display: flex;
  align-items: center;
}

.MuiAutocomplete-BookAppointmentModal-icon {
  margin-left: 5px;
  margin-top: 3px;
}

.MuiAutocomplete-BookAppointmentModal-icon.green {
  color: #2e7d32;
}

.MuiAutocomplete-BookAppointmentModal-icon.red {
  color: #FF5023;
}

.MuiAutocomplete-BookAppointmentModal-icon.yellow {
  color: #f1a852;
}

.MuiAutocomplete-BookAppointmentModal-icon.grey {
  color: #9e9e9e;
}

.book-appointment-modal-dropdown-group-label {
  padding: 8px 0px;
}

.book-appointment-modal-dropdown-group-label-icon {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
