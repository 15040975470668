
.my-appt {
  margin-top: 30px;
}

.my-appt-wrapper {
  height: calc(100vh + 20px);
}

.my-appt-table-header {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #D5D8DF;
  background-color: #EFF0F1;
  font-weight: bold;
}

.my-appt-table-header-col {
  width: 10%;
  display: flex;
  align-items: center;
  word-break: break-all;
  padding-right: 10px;
}

.my-appt-table-header-col.shipping_line {
  width: 6%;
}

.my-appt-table-header-col.datetime {
  width: 14%;
}

.my-appt-table-header-col.sotrable:hover {
  cursor: pointer;
}

.my-appt-table-body-row {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #D5D8DF;
  transition: background-color .2s;
}

.my-appt-table-body-row:last-child {
  border-bottom: none;
}

.my-appt-table-body-row:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.04);
  transition: background-color .2s;
}

.my-appt-table-body-row-col {
  width: 10%;
  word-break: break-all;
  padding-right: 10px;
}

.my-appt-table-body-row-col.shipping_line {
  width: 6%;
}

.my-appt-table-body-row-col.datetime {
  width: 14%;
}

.my-appt-table-body-row-col-status-label {
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  border-radius: 3px;
  padding: 4px 8px;
  display: inline-block;
  text-transform: uppercase;
  background-color: #F1A852;
}

.my-appt-table-body-row-col-status-label.confirmed {
  background-color: #51A221;
}

.my-appt-table-body-row-col-status-label.failed {
  background-color: #D3000B;
}

.my-appt-pagination {
  display: flex;
  justify-content: flex-end;
  margin: 20px 80px 0px 0px;
}

.my-appt-no-results {
  display: flex;
  justify-content: center;
  font-style: italic;
  padding: 15px;
}
