
.stop-the-clock-feedback {
  position: fixed;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.stop-the-clock-feedback__content {
  color: #FFFFFF;
  background-color: #2C67F6;
  border-radius: 6px;
  padding: 10px 20px;
}
