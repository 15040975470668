
.show-hide-shifts {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
}

.show-hide-shifts:hover {
  cursor: pointer;
}

.show-hide-shifts-icon {
  display: flex;
  align-items: center;
}
