
.empty-appoint-modal-wrapper {
  display: flex;
}

.empty-appoint-modal-main-icon-container {
  padding-left: 50px;
  display: flex;
  align-items: center;
  min-width: 400px;
}

.empty-appoint-modal-iframe {
  width: 100%;
  height: 310px;
}

.empty-appoint-modal-iframe > iframe {
  width: 100%;
  height: 100%;
}

.empty-appoint-modal-content {
  padding: 16px 50px 16px 50px;
}

.empty-appoint-modal-title {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 30px;
}

.empty-appoint-modal-text-block {
  display: flex;
  align-items: baseline;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 25px;
}

.empty-appoint-modal-icon-container {
  width: 15px;
  min-width: 15px;
  height: 15px;
  border-radius: 8px;
  background-color: #51A221;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 23px;
}

.empty-appoint-modal-button-container {
  display: flex;
  justify-content: center;
}

.empty-appoint-modal-button {
  background-color: #F1A852;
  color: #FFFFFF;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  padding: 8px 20px;
  border-radius: 17px;
  transition: opacity .1s;
}

.empty-appoint-modal-button:hover {
  opacity: .8;
  cursor: pointer;
  transition: opacity .1s;
}
