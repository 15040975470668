.my-appt-search {
  margin-top: 20px;
  display: flex;
}

.my-appt-search-title {
  margin-bottom: 3px;
  font-size: 14px;
  color: #8D97A3;
  transition: color .2s;
}

.my-appt-search-title.active {
  color: #1F334B;
  transition: color .2s;
}

.my-appt-search-input {
  min-width: 223px;
  background: #FFFFFF;
  border: 1px solid #D5D8DF;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  line-height: 16px;
  padding: 6px 12px;
}

.my-appt-search-input::placeholder {
  color: #8E98A4;
}

.my-appt-search-input-button {
  display: flex;
}

.my-appt-search-button-wrapper {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.my-appt-search-button {
  padding: 8.5px 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #18294D;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 16px;
  border-radius: 19px;
  transition: opacity .2s;
}

.my-appt-search-button:hover {
  opacity: .8;
  transition: opacity .2s;
  cursor: pointer;
}

.my-appt-search-button.disabled {
  background-color: #8E98A4;
}

.my-appt-search-button.disabled:hover {
  cursor: auto;
  opacity: 1;
}

.my-appt-search-dropdowns {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-left: 60px;
}

.my-appt-search-dropdown-container {
  width: 40%;
  max-width: 250px;
}

.my-appt-search-dropdown-container:first-child {
  margin-right: 40px;
}

.my-appt-search-warning {
  position: absolute;
  color: #D3000B;
}
